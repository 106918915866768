<template>
    <div>
        <v-card flat class="my-0 py-0">
            <v-card-text>
                <v-row justify="end">
                    <v-col cols="12" sm="12" md="4" lg="6" class="text-left">
                        <el-button v-if="params.filter && Object.keys(params.filter).length > 0" type="plain"
                            @click="clearFilter" color="error">
                            <v-icon small>close</v-icon> Clear Filters
                        </el-button>
                    </v-col>
                    <v-col cols="12" sm="12" md="8" lg="6">
                        <el-input suffix-icon="el-icon-search" v-model="params.search" clearable placeholder="Search"
                          >
                            <template slot="prepend">
                                <el-button @click="createModule()"><i class="el-icon-plus"></i></el-button>
                            </template>
                        </el-input>
                    </v-col>
                </v-row>
                    <v-data-table :footer-props="{ 'items-per-page-options': [25, 30, 50, 100] }" :search="params.search" :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc" dense :loading="loading" :items="modules.data" :headers="headers">
                        <template v-slot:item.action="{ item }">
                            <el-button size="mini" plain @click="routeToModuleView(item)" icon="el-icon-edit"
                                circle></el-button>
                            <el-button size="mini" type="danger" icon="el-icon-delete" circle
                                @click="removeModule(item)"></el-button>
                        </template>
                        <template v-slot:item.shareable="{ item }">
                         <v-icon v-if="item.shareable" color="success">check_circle_outline</v-icon>
                         <v-icon v-else color="red">cancel</v-icon>
                        </template>
                        <template v-slot:item.isActive="{ item }">
                            <v-chip style="cursor: pointer" @click="changeActiveStatus(item)" outlined small
                                :color="item.isActive ? 'success' : 'danger'">{{
                                    item.isActive ? 'Active' :
                                        'Inactive'
                                }}</v-chip>
                        </template>
                    </v-data-table>

                <!-- <v-dialog v-model="moduleModal" width="500px" :fullscreen="$vuetify.breakpoint.mobile">
                    <v-card flat>
                        <v-toolbar flat color="transparent">
                            <v-toolbar-title>Add Module </v-toolbar-title>
                            <v-spacer></v-spacer><v-btn text @click="moduleModal = false">X</v-btn>
                        </v-toolbar>
                        <v-card-text>
                            <v-text-field label="Name" dense outlined clearable v-model="item.name"></v-text-field>
                            <v-textarea rows="5" auto-grow label="Description" outlined clearable
                                v-model="item.description"></v-textarea>
                            <v-row align="center">
                                <v-col cols="12" xs="12" sm="6">
                                    <v-select label="Rate Type" v-model="item.rateType" outlined dense
                                        :items="priceTypes"></v-select>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6">
                                    <v-text-field label="Rate per unit" prefix="$" outlined clearable dense
                                        v-model="item.rate"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-dialog> -->
                <v-dialog v-model="moduleModal" width="400px" :fullscreen="$vuetify.breakpoint.mobile">
                    <v-card>
                        <v-toolbar flat>
                            {{ item.modalType }} Module
                            <v-spacer></v-spacer>
                            <v-btn icon color="secondary" @click="saveModule" :loading="loading">
                                <v-icon>save</v-icon>
                            </v-btn>
                            <v-btn text @click="moduleModal=false">X</v-btn>
                        </v-toolbar>
                        <v-card-text>
                            <v-text-field hide-details label="Name" v-model="item.name" outlined dense clearable></v-text-field>
                            <v-textarea v-model="item.description" label="Description"></v-textarea>
                            <v-switch v-model="item.shareable" dense label="Shareable"></v-switch>
                            <v-autocomplete v-if="item.shareable" :items="modules.data" item-text="name" item-value="id" outlined dense label="Source Module" v-model="item.sourceModuleId"></v-autocomplete>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <!-- <el-dialog :visible.sync="moduleModal" :fullscreen="$vuetify.breakpoint.mobile" width="750px" :title="item.modalType + ' Module'" :closed="clear">
        <el-form class="modal-form" :model="item" ref="form" @submit.native.prevent="saveOrganisation">
            <el-form-item prop="name">
                <el-input v-model="item.name" placeholder="Name" prefix-icon="fas fa-user" clearable>
                    <v-icon v-if="item.name" slot="suffix" class="mr-1" small color="green">check</v-icon>
                    <v-icon v-else slot="suffix" class="mr-1" small color="orange">warning</v-icon>
                </el-input>
            </el-form-item>

            <el-form-item style="text-align: center">
                <el-button v-if="item.modalType=='Add'" :loading="loading" style="min-width: 40%" type="primary" :disabled="!item.name" native-type="submit" block> {{!loading?'Create Module':''}}</el-button>
                <el-button v-else :loading="loading" style="min-width: 40%" type="primary" :disabled="!item.name" native-type="submit" block> {{!loading?'Update Module':''}}</el-button>
            </el-form-item>
        </el-form>
    </el-dialog> -->
            </v-card-text>
        </v-card>

    </div>
</template>

<script>
export default {
    data: () => ({
        headers: [{
            text: 'Action',
            align: 'center',
            sortable: false,
            value: 'action'
        },
        {
            text: 'Name',
            value: 'name',
            align: 'center'
        },
        {
            text: 'Shareable',
            value: 'shareable',
            align: 'center'
        },
        {
            text: 'Source Module',
            value: 'sourceModule.name',
            align: 'center'
        },

        {
            text: 'Status',
            value: 'isActive',
            align: 'center'
        }],
        loading: false,
        page: 1,
        params: {
            // limit: 15,
            offset: 0,
            search: null
        },
        item: {},
        modules: {
            count: 0,
            data: []
        },
        moduleModal: false,
        priceTypes: [
            "Per Pallet",
            "Per Container",
            "Per Shipment",
            "Flat Rate"
        ],
        sortDesc: false,
        sortBy: 'name',
    }),
    watch: {
        async page(value) {
            this.params.offset = (value - 1) * this.params.limit
            await this.getModules()
        },
        'params.limit': {
            immediate: true,
            async handler() {
                await this.getModules()

            }
        }
    },
    async created() {
        this.getModules()
    },
    methods: {
        async changeActiveStatus(module) {
            module.isActive = !module.isActive
            await this.$API.updateModule({
                id: module.id,
                isActive: module.isActive
            })
            this.$message({
                type: 'success',
                message: 'Successfully updated!'
            });
        },
        clearFilter() {
            this.$refs.filterTable.clearFilter();
            this.params.filter = {}
            this.getModules()
        },
        createModule() {
            this.item = {
                modalType: 'Add',
                tagId: 3
            }
            this.moduleModal = true
        },
        editModule(org) {
            this.item = org
            this.item.modalType = 'Edit'

            this.moduleModal = true
        },
        async getModules() {
            this.loading = true
            this.modules = await this.$API.getModules({
                params: this.params
            })
            this.loading = false
        },
        removeModule(org) {
            this.$confirm('Are you sure you want to delete this Module?', 'Confirm', {
                center: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'plain'
            }).then(async () => {
                await this.$API.updateModule({
                    id: org.id,
                    isActive: false,
                    isDeleted: true
                })
                this.getModules()
                this.$message({
                    type: 'success',
                    message: 'Successfully deleted!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Delete canceled'
                });
            });
        },
        routeToModuleView(module) {
            this.$router.push({
                path: '/module/' + module.id
            })
        },
        async saveModule() {
            this.loading = true
            if (this.item.id) {
                await this.$API.updateModule(this.item)
                this.$message.success('Update successful!')

            } else {
                await this.$API.createModule(this.item)
                this.$message.success('Successfully create user!')
                this.getModules()
            }
            this.loading = false
            this.item = {}
            this.moduleModal = false
        },
        clear() {
            this.loading = false
            this.item = {}
            this.moduleModal = false
        }
    }
}
</script>

<style scoped>
.modal-form {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 10px;
}
</style>
